<!-- 二级路由 -->
<template>
  <!--  -->
  <div class="content" :class="{ has_breadcrumb: breadcrumbData.length > 0 }">
    <base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
    <GgPage pageType="2" :title="title">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <GgPageHeader
        :userInfo="userInfo"
        :menuData="menuData"
        :title="userInfo.title"
        :subTitle1="userInfo.subTitle1"
        @_uploadCallbackLogo="_uploadCallbackLogo"
        @menuCallback="handleMenuCallback"
		:editimg="($buttonPermission('Staff', 'Full') ||
            $buttonPermission('Staff', 'Edit')) && !people"
      >
        <div
          v-if="formDialogLocations.checkedData.length > 0"
          slot="title-label"
          class="title-label"
          @click="formDialogLocations.viewLocations = true"
        >
          <span>{{ viewLabel }}</span>
          <img
            v-if="formDialogLocations.checkedData.length > 1"
            class="icon-view"
            src="@/assets/images/icons/icon-view.png"
            alt=""
          />
        </div>
        <div slot="subTitleLabel" class="title-label" v-if="userInfo.roleName">
          {{ userInfo.roleName }}
        </div>
        <div slot="info">
          <div class="title-status mar-b-0">
            {{ userInfo.status[0].toUpperCase()
            ||''}}{{ userInfo.status.substring(1) ||''}}
          </div>
        </div>
      </GgPageHeader>
      <router-view></router-view>
    </GgPage>
    <!-- edit user -->
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      @submit="handleAddForm"
      textReminder="* indicates a required field"
      :renderPass="formDialogAdd.renderPass"
      :ref="formDialogAdd.ref"
    >
      <template slot="renderHtml" slot-scope="scope">
        <gg-input
          style="margin-bottom: 40px"
          v-if="scope.data.key === 'emailAddress'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.emailAddress"
          :placeholder="scope.data.placeholder"
          :error="formDialogAdd.emailErrorObj"
        />
        <baseGGSelect
          v-else-if="scope.data.key === 'roleId'"
          v-model="formDialogAdd.dataRender.roleId"
          :placeholder="scope.data.placeholder"
          :key="scope.data.key"
          :ref="scope.data.key"
          class="float"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </md-option>
        </baseGGSelect>
        <baseGGSelect
          v-else-if="scope.data.key === 'locationId'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.locationId"
          :placeholder="scope.data.placeholder"
          class="float"
          md-dense
          multiple
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item.id"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>
      </template>
      <template slot="footer">
        <baseButton @click="handleAddCancel">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAdd.isDisabled"
          @click="handleAddForm"
          >{{ formDialogAdd.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>
    <!-- resetpassword dialog -->
    <gg-Dialog
      :visible.sync="formDialogPwd.visible"
      :before-close="cancelResetPwd"
      width="580px"
      ref="resetPassword"
    >
      <div slot="title" style="font-weight: normal;">
        Reset password for
        <span class="title-bold">{{ formDialogPwd.name }}</span>
      </div>
      <div>
        <ResetPassword
          @sendEmail="resetByEmail"
          :autoSend="formDialogPwd.autoSend"
          v-model="formDialogPwd.data"
        ></ResetPassword>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
        <gg-submit-button
          :disabled="formDialogPwd.isDisabled"
          @click="handleResetPwd"
          >Reset</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <!-- view locations -->
    <gg-Dialog
      :append-to-body="false"
      :visible.sync="formDialogLocations.viewLocations"
	  v-if='formDialogLocations.viewLocations'
      :show-close="true"
      title="Locations"
    >
      <div class="dialog-content">
        <CheckboxGroup
          :checkTitle="formDialogLocations.checkTitle"
          :checkedData="formDialogLocations.checkedData"
          :checkboxList="formDialogLocations.checkboxList"
          @check="handleCheck"
        />
      </div>
    </gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Ajax } from "@/common";
import { lightFormat } from "date-fns";
export default {
  components: {},
  data() {
    return {
		people:false,
      menuDatas: [],
      menuData: [],
      activeIndex: "",
      title: "",
      breadcrumbData: [],
      userInfo: {
        title: "",
        avatar: "",
        firstName: "",
        lastName: "",
        subTitle1: "",
        status: "Unknown",
      },
      formDialogPwd: {
        visible: false,
        data: "",
        autoSend: true,
        name: "",
        id: "",
        isDisabled: false,
      },
      formDialogAdd: {
        ref: "formDialogAdd",
        visible: false,
        title: "Edit staff",
        submitBtnTxt: "Save",
        renderPass: false,
        formData: {},
        isDisabled: true,
        dataRender: {
          emailAddress: "",
          roleId: "",
          locationId: [],
        },
        emailErrorObj: {
          show: false,
          message: "",
        },
        slotData: ["roleId", "locationId"],
        dataList: [
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            autofocus: true,
            float: true,
            reg: /[\S]/,
            errorText: "First name required",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /[\S]/,
            errorText: "Last name required",
          },
          {
            placeholder: "Email address *",
            key: "emailAddress",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Role *",
            key: "roleId",
            float: true,
            value: "",
            itemList: [],
            renderHtml: true,
          },
          {
            placeholder: "Location *",
            key: "locationId",
            value: [],
            itemList: [],
            renderHtml: true,
          },
        ],
      },
      formDialogLocations: {
        viewLocations: false,
        checkTitle: "Raffles Kidz International",
        checkedData: [],
        checkboxList: [],
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  watch: {
    "formDialogPwd.data": function (res) {
      this.formDialogPwd.isDisabled = res.length < 8;
    },
    "formDialogAdd.dataRender.emailAddress": function (res) {
      let isPass = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(res);
      if (res.length === 0) {
        this.formDialogAdd.emailErrorObj = {
          show: true,
          message: "Email required",
        };
      } else if (!isPass) {
        this.formDialogAdd.emailErrorObj = {
          show: true,
          message: "Email address is invalid",
        };
      } else {
        this.formDialogAdd.emailErrorObj = {
          show: false,
          message: "",
        };
      }
    },
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          const isForm = this.$refs.formDialogAdd.valid(); // 表单其他非插槽元素是否验证通过
          const isRender = res.slotData.every(
            // 插槽数据是否通过校验
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          let locationPass =
            this.$refs.locationId && this.$refs.locationId.value.length > 0;

          res.renderPass = res.isDisabled =
            isForm &&
            isRender &&
            locationPass &&
            !this.$refs.emailAddress.error.show;
        });
      },
    },
    $route(route) {
      if (route.query.people) {
        if (route.name === "staffPrivileges") {
          this.menuData = [];
          this.breadcrumbData = this.breadcrumb.breadcrumbData || [];
        } else {
          this.breadcrumbData = this.breadcrumb.breadcrumbData || [];
          this.menuData = [];
        }
      } else if (route.name === "staffPrivileges") {
        this.$store.commit("SET_BREADCRUMDATA", [
          {
            routeName: "staff",
            name: "Staff",
          },
          {
            routeName: "staff",
            name: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
          },
          {
            name: "Privileges",
          },
        ]);
        this.menuData = [];
        this.breadcrumbData = this.breadcrumb.breadcrumbData || [];
      } else {
        this.$store.commit("SET_BREADCRUMDATA", [
          {
            routeName: "staff",
            name: "Staff",
          },
          {
            routeName: "staff",
            name: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
          },
          {
            name: "Personal info",
          },
        ]);
        this.breadcrumbData = this.breadcrumb.breadcrumbData || [];
        switch (this.userInfo.status) {
          case "active":
		  
		  
		  
            this.menuData = ["Reset password", "Edit", "Delete"];
            break;
          case "archived":
            this.menuData = ["Restore"];
            break;
        }
      }
    },
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
    }),
    userId() {
      return JSON.parse(sessionStorage.getItem("staffInfo")).userId;
    },
    viewLabel() {
      let checkedDataLen = this.formDialogLocations.checkedData.length,
        checkboxListLen = this.formDialogLocations.checkboxList.length;
      if (checkedDataLen == 1) {
        return this.formDialogLocations.checkedData[0];
      } else if (checkedDataLen == checkboxListLen) {
        return "All locations";
      } else {
        return checkedDataLen + " locations";
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let menuDatas = to.meta.menuDatas;
      vm.menuDatas = menuDatas;
      vm.activeIndex = to.name;
      if (vm.$route.query.people) {
        vm.title = "People";
		vm.people=true
        vm.menuData = [];
      } else if (vm.$route.query.class) {
        vm.title = "Classes";
			vm.menuData = [];
        
      } else {
        vm.title = to.meta.pageTitle;
      }
      vm.breadcrumbData = vm.breadcrumb.breadcrumbData || [];
    });
  },
  methods: {
    filterMenuData(moduleName, buttonName) {
      let rolePermission = this.$store.state.user.rolePermission || [];
      let permissonArr = rolePermission.filter(
        (item) => item.indexOf(moduleName) > -1
      );
      if (permissonArr.indexOf(`${moduleName}_${buttonName}`) > -1) {
        return [];
      }
    },
    handleCheck(val) {
      this.formDialogLocations.checkedData = val;
    },
    getRoleList() {
      let params = {
        userId: this.userId,
      };

      Ajax.get("/usermanage/role/getRoleByBelongId", params)
        .then((res) => {
          this.formDialogAdd.dataList.find(
            (item) => item.key === "roleId"
          ).itemList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLocation() {
      let params = {
        userId: this.userId,
        dataLevel: "location",
        dataType: "location",
      };

      Ajax.post("/usermanage/setting/getList", params).then((res) => {
        this.formDialogAdd.dataList.find(
          (item) => item.key === "locationId"
        ).itemList = res.data;
        let a = {};
		this.formDialogLocations.checkboxList=[]
        res.data.forEach((item) => {
          a = {
            name: item.value,
            id: item.id,
          };
          this.formDialogLocations.checkboxList.push(a);
        });
		this.formDialogLocations.viewLocations=true
		this.formDialogLocations.viewLocations=false
		this.$forceUpdate()
		console.log(this.formDialogLocations.checkboxList)
		console.log(this.formDialogLocations.checkedData)
      });
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    _uploadCallbackLogo(val) {
      this.userInfo.avatar = val;
      let params = {
        photo: val,
        userId: this.userId,
      };
      Ajax.post("/usermanage/staff/updateStaff", params)
        .then((res) => {
          if (res.code === "0000") {
			 
            this.getData();
			 // this.getLocation()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    menuSelect(key) {
      if (JSON.parse(sessionStorage.getItem("isChanged"))) {
      } else {
        this.activeIndex = key;
      }
      if (this.$route.query.people) {
        this.$router.push({ name: key, query: { people: true } });
      } else {
        this.$router.push({ name: key });
      }
    },
    getData() {
      let params = {
        id: this.userId,
      };

      Ajax.get("/usermanage/staff/user/selectStaffByUserId", params).then(
        (res) => {
          if (res.code === "0000") {
            let {
              photo,
              firstName,
              lastName,
              emailAddress,
              status,
              companyId,
              roleId,
              locations,
              roleName,
            } = res.data;
            this.userInfo = {
              title: `${firstName} ${lastName}`,
              avatar: photo,
              firstName: firstName,
              lastName: lastName,
              subTitle1: emailAddress,
              emailAddress: emailAddress,
              status: status,
              companyId: companyId,
              roleId: roleId,
              locations: locations,
              roleName: roleName,
            };
            // this.formDialogLocations.checkboxList = locations || [];
            this.formDialogLocations.checkTitle = res.data.companyName;
			this.formDialogLocations.checkedData=[]
            if (locations) {
				this.formDialogLocations.checkedData = []
              locations.forEach((item) => {
                this.formDialogLocations.checkedData.push(item.name);
              });
            }
			this.getLocation()
            switch (status) {
              case "active":
			  
			  
			  if(this.$buttonPermission('Staff', 'Full')){
			  	this.menuData = ["Reset password", "Edit", "Delete"];
			  }else if(this.$buttonPermission('Staff', 'Edit')){
			 this.menuData = ["Reset password", "Edit"];
			  	
			  	
			  }else{
			  	this.menuData = [];
			  }
			  
                // this.menuData = ["Reset password", "Edit", "Delete", "Archive"];
                break;
              case "archived":
			  
			  if(this.$buttonPermission('Staff', 'Full')){
			   this.menuData = ["Restore"];
			  }else if(this.$buttonPermission('Staff', 'Edit')){
			    this.menuData = [];
			   }else{
			   	this.menuData = [];
			   }
			  
              
                break;
            }
			if (this.$route.query.class) {
						this.menuData = [];
			  
			}
			if (this.$route.query.people) {
						this.menuData = [];
			  
			}
          }
        }
      );
    },
    handleMenuCallback(item) {
      switch (item) {
        case "Reset password":
          this.openDialogPwd();
          break;
        case "Edit":
          this.openDialogAdd();
          break;
        case "Delete":
          // this.handleDelete();
		   this.handleArchive();
          break;
        case "Archive":
          this.handleArchive();
          break;
        case "Restore":
          this.handleActive();
          break;
        default:
          return;
      }
    },
    resetByEmail(val) {
      this.formDialogPwd.autoSend = val;
      if (val || this.formDialogPwd.data.length > 8) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    openDialogPwd() {
      this.formDialogPwd.name = `${this.userInfo.firstName} ${this.userInfo.lastName}`;
      this.formDialogPwd.id = this.userId;
      this.formDialogPwd.autoSend = true;
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.visible = true;
    },
    cancelResetPwd() {
      this.formDialogPwd.visible = false;
      this.formDialogPwd.data = "";
    },
    handleResetPwd() {
      let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
      this.formDialogPwd.visible = false;
      Ajax.post("/usermanage/password/resetPasswd", {
        userId: this.formDialogPwd.id,
        newPasswd: this.formDialogPwd.data,
        isAuto: isAuto,
      })
        .then((res) => {
          if (res.code === "0000") {
            this.handleSnack("Password has been reset");
            this.formDialogPwd.data = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDialogAdd() {
      this.formDialogAdd.id = this.userId;
      this.formDialogAdd.dataList.forEach((i) => {
        if (this.userInfo[i.key]) {
          i.value = this.userInfo[i.key];
        }
      });

      Object.keys(this.formDialogAdd.dataRender).forEach((item) => {
        this.formDialogAdd.dataRender[item] = this.userInfo[item];
      });

      this.formDialogAdd.dataRender.locationId = [];
      if (!!this.userInfo.locations) {
        this.userInfo.locations.forEach((location) => {
          this.formDialogAdd.dataRender.locationId.push(location.id);
        });
      }

      this.formDialogAdd.visible = true;
    },
    fillData() {
      let obj = {};
      this.$refs.formDialogAdd.dataExceptRender.forEach((item) => {
        obj[item.key] = item.value;
      });

      this.formDialogAdd.formData = Object.assign(
        {},
        obj,
        this.formDialogAdd.dataRender
      );
    },
    handleAddCancel() {
      this.formDialogAdd.visible = false;
    },
    handleAddForm(res) {
      this.fillData();
      let params = {};
      params = Object.assign({}, this.formDialogAdd.formData, {
        userId: this.formDialogAdd.id,
      });

      Ajax.post("/usermanage/staff/updateStaff", params)
        .then((res) => {
          if (res.code === "0000") {
            this.getData();
			 // this.getLocation()
			 
            this.handleSnack("Staff has been edited");
            let fullName = {};
            this.$refs.formDialogAdd.dataExceptRender.forEach((item) => {
              fullName[item.key] = item.value;
            });
            this.$store.commit("SET_BREADCRUMDATA", [
              {
                routeName: "staff",
                name: "Staff",
              },
              {
                name: `${fullName.firstName} ${fullName.lastName}`,
              },
            ]);
            this.formDialogAdd.visible = false;
          } else {
            if (res.message === "Email already exists") {
              this.formDialogAdd.emailErrorObj = {
                show: true,
                message: res.message,
              };
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDelete(row) {
      this.$confirm(
        "Once deleted, the staff will not be able to access the app.",
        "Delete staff ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/staff/updateStatus", {
            companyId: this.userInfo.companyId,
            userId: this.userId,
            status: "delete",
          })
            .then((res) => {
              if (res.code === "0000") {
                // this.getData();
				sessionStorage.setItem('delete','staff')
               this.$router.go(-1)
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    handleArchive() {
      this.$confirm(
        "Once deleted, the staff will move to deleted.",
        "Delete staff ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/staff/updateStatus", {
            companyId: this.userInfo.companyId,
            userId: this.userId,
            status: "archived",
          })
            .then((res) => {
              if (res.code === "0000") {
                this.getData();
                this.handleSnack("Staff has been deleted");
              }
            })
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleActive() {
      this.$confirm(
        "Once the staff is restored, you will be able to recover the staff data.",
        "Restore staff ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Restore",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/staff/updateStatus", {
            companyId: this.userInfo.companyId,
            userId: this.userId,
            status: "active",
          })
            .then((res) => {
              if (res.code === "0000") {
                this.getData();
                this.handleSnack("Staff has been restored");
              }
            })
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getData();
    this.getLocation();
    this.getRoleList();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/.el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: transparent;
}
/deep/.gg_input label{
		font-family: Roboto;
	}
	/deep/.menu_bar {
	  margin-top: 12px;
	  width: 40px;
	  height: 40px;
	  line-height: inherit;
	  .drop_down_menus {
	    padding: 0;
	  }
	  .iconmore_vert-24px{
		  font-size: 24px;
		  line-height: 40px;
	  }
	}
</style>